/* eslint-disable @typescript-eslint/no-unused-vars */
import Vue from 'vue';
import API from '@cloudpivot-shared/domain-api';
import store from '@WorkPlatform/store';
import { LoginService } from '@WorkPlatform/api';
import {
  isPC, parseQuery, removeCookie, setCookie,
} from '@WorkPlatform/common/utils';
import cookie from 'js-cookie';
import { formatter, startup } from './startup';
import {
  dingTalkInit,
  IS_DINGTALK,
  IS_WECHAT,
  service,
  IS_IOS,
  dd,
} from '@cloudpivot-hermes/nail-free-login';
// import VConsole from 'vconsole';
import IconFont from '@WorkPlatform/common/iconfont';

(window as any).$cookie = cookie;

(window as any).dd = dd;

const filename = 'iconfont.min.js';
// IconFont.loadingIconFont(Vue, '../iconfont.min.js');
IconFont.loadingIconFont(
  Vue,
  process.env.BASE_URL
    ? `${process.env.BASE_URL.replace(/\/$/, '')}/${filename}`
    : filename,
);

API.ErrorCatcher.create({
  dev: false, // 是否开发模式，决定左侧错误panel是否展示
});

// const vConsole = new VConsole();

// 是否是免登url传参
// function isFreeLogin () {
//   return parseQuery(location.href).token;
// }

const setInfoToLocal = (data: any, originalData: any) => {
  const userInfo: any = {
    name: data ? data.name : '-',
    username: data ? data.username : '-',
    mobile: data.mobile || '-',
    userId: data.userId,
    imgUrl: data.imgUrl,
  };
  localStorage.setItem('userInfo', JSON.stringify(userInfo));
  localStorage.setItem('corpInfo', JSON.stringify(originalData));
};

async function setUserInfo () {
  const res = await LoginService.getLoginInfo();
  if (res.success) {
    const data: any = res.data || {};
    const userInfo = {
      name: data.name,
      username: data.username,
      mobile: data.mobile,
      userId: data.id,
      baseUserId: data.baseUserId,
      imgUrl: data.imgUrl,
    };

    setInfoToLocal(userInfo, data);
  }
}

async function openMessage (messageId: string) {
  API.Global.request({
    url: '/api/webapi/get_message_form_url',
    method: 'get',
    params: { messageId },
  }).then(res => {
    if (res.data && res.data.targetUrl) {
      const { messageId: msgId, appVersion } = parseQuery(res.data.targetUrl) || {};
      // console.log('parseQuery', parseQuery(res.data.targetUrl).messageId);
      if (msgId) {
        getMessageUrl({ messageId: msgId, appVersion, targetUrl: res.data.targetUrl });
      } else {
        init(res.data.targetUrl, messageId);
      }
    }
  });
}

/**
 * 获取message地址
 */
function getMessageUrl (params: any) {
  API.Global.request({
    url: '/api/todo/todo/get_single_todo',
    method: 'get',
    params: { messageId: params.messageId },
  }).then(res => {
    if (res.success) {
      const result = res.data;
      const token = localStorage.getItem('token');
      const pageData: any = formatter(result);
      const freetype = IS_WECHAT ? 'wei' : 'dingtalk';
      // eslint-disable-next-line max-len
      let url = `${params.targetUrl.split('/home/')[0]}/home/${result.appCode}?workItemId=${result.id}&workflowInstanceId=${result.instanceId}&appVersion=${params.appVersion}&appCode=${pageData.appCode}&groupCode=${pageData.groupCode}&moduleCode=${pageData.moduleCode}&pageGroupCode=${pageData.pageGroupCode}&pageCode=${pageData.pageCode}&pageType=${pageData.pageType}&freelogin=${freetype}&from=message&token=${token}`;
      if ([null, '0.0.0'].includes(params.appVersion)) {
        url += '&isMock=1';
      }
      setCookie('queryMsg', JSON.stringify({
        messageId: params.messageId,
        targetUrl: url,
      }), {
        expires: new Date(Number(localStorage.getItem('expireTime'))),
      });
      if (IS_DINGTALK || IS_WECHAT) {
        blankTips(url);
      }
    }
  });
}

function toPage (url: string) {
  if (IS_DINGTALK || IS_WECHAT) {
    blankTips(url);
  }
}

async function init (targetUrl: string, messageId: string) {
  const token = localStorage.getItem('token');
  const freetype = IS_WECHAT ? 'wei' : 'dingtalk';
  // eslint-disable-next-line max-len
  const url = `${targetUrl.replace('/#', '/?#')}&freelogin=${freetype}&from=message&token=${token}`;
  setCookie('queryMsg', JSON.stringify({
    messageId,
    targetUrl: url,
  }), {
    expires: new Date(Number(localStorage.getItem('expireTime'))),
  });
  if (IS_DINGTALK || IS_WECHAT) {
    blankTips(url);
  }
}

function blankTips (url: string) {
  let newUrl = url;
  if (!url.includes('tenantCode=')) {
    newUrl += `&tenantCode=${sessionStorage.getItem('tenantCode')}`;
  }
  const $app = document.getElementById('app');
  if (!$app) {
    throw new Error('can\'t find #app');
  }
  $app.innerText = '';
  $app.style.textAlign = 'center';
  if (isPC()) {
    $app.style.paddingTop = '130px';
    $app.style.color = '#666';
    $app.style.fontSize = '22px';
  } else {
    $app.style.paddingTop = '50px';
    $app.style.color = '#666';
    $app.style.fontSize = '12px';
  }

  $app.style.position = 'relative';
  $app.style.width = '100%';
  $app.style.height = '100%';

  const $a = document.createElement('a');
  $a.style.textDecoration = 'underline';
  $a.href = newUrl;
  $a.id = 'jump_url';
  $a.target = '_blank';
  $a.innerText = '页面跳转中...请稍后...';
  // '已经在外部浏览器打开该页面'
  $app.appendChild($a);
  jumpUrl(newUrl);
}

// 微信钉钉页面跳转
function jumpUrl (url: string) {
  const isMobile = /Android|webOS|iPhone|iPod|iPad|BlackBerry/i.test(navigator.userAgent);
  const sleepTime: number = 300;
  if (IS_WECHAT) {
    console.log('wechat', url, isMobile);
    try {
      if (isMobile) {
        setTimeout(() => {
          // 待办菜单移动端
          if (parseQuery(location.href).public_blank_open === 'true') {
            window.location.href = url.split('?')[0];
            location.reload();
          } else {
            window.location.href = url;
            location.reload();
          }
        }, sleepTime);
      } else {
        service.openLink(url);
      }
    } catch (err) {
      console.log('err', err);
    }
  } else {
    try {
      if (!isPC()) {
        window.location.replace(url);
      } else {
        service.openLink(url);
      }
    } catch (err) {
      console.log('err', err);
    }
  }
}

// 配置待办菜单 在浏览器打开 工作台
async function openPublicBlank () {
  const locationOrigin: any = location.href.split('?corpId')[0];
  const token: any = localStorage.getItem('token');
  const locationOpen: any = `${locationOrigin}?token=${token}`;
  blankTips(locationOpen);
}

async function start () {
  try {
    const result: any = await dingTalkInit();

    if (!result) return;
    const { query } = result;
    sessionStorage.IS_DINGTALK = IS_DINGTALK;
    sessionStorage.IS_WECHAT = IS_WECHAT;
    const urlQuery = parseQuery(location.href);
    const token = urlQuery.token || localStorage.getItem('token');

    localStorage.setItem('openLogoConfig', 'true');
    if (!token) {
      console.warn('href ->', location.href);
      console.warn('用户未登录！');
      // return;
    } else {
      document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      document.cookie = 'access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/platform;';
      localStorage.setItem('token', token);
      setCookie('access_token', token, {
        path: '/',
      });
    }

    let tenantCode = urlQuery.tenantCode;
    if (!tenantCode) {
      tenantCode = sessionStorage.getItem('tenantCode');
      if (!tenantCode) {
        tenantCode = localStorage.getItem('tenantCode');
      }
    }
    if (tenantCode) {
      sessionStorage.setItem('tenantCode', tenantCode);
      localStorage.setItem('tenantCode', tenantCode);
      setCookie('tenantCode', tenantCode);
      window.addEventListener('focus', () => {
        const code = sessionStorage.getItem('tenantCode') || localStorage.getItem('tenantCode') || '';
        setCookie('tenantCode', code);
      });
    }
    removeCookie('appCode');
    window.addEventListener('focus', () => {
      removeCookie('appCode');
    });

    if (token) {
      await setUserInfo();
    }

    // if (isFreeLogin()) {
    //   const token = parseQuery(location.href).token;
    //   localStorage.setItem('token', token);
    //   document.cookie = `access_token=${token};path=/;`;
    //   await setUserInfo();
    // }

    if (query.messageId) {
      const queryMsg = JSON.parse(cookie.get('queryMsg') || '{}');
      if (cookie.get('queryMsg') && queryMsg?.messageId === query.messageId) {
        if (IS_DINGTALK || IS_WECHAT) {
          if (isPC()) {
            toPage(queryMsg?.targetUrl);
          } else {
            if (IS_IOS) {
              openMessage(query.messageId);
            } else {
              toPage(queryMsg?.targetUrl);
            }
          }
        }
      } else {
        openMessage(query.messageId);
      }
    } else if (query.public_blank_open) {
      openPublicBlank();
    } else {
      startup();
    }
  } catch (error) {
    startup();
    console.log('startError', error);
  }
}

window.onload = function () {
  const iconLink = document.getElementsByTagName('link')[0];
  // 刷新时，设置标签图标为loading-default.png
  // iconLink.href = 'loading-default.png';
  // 获取并设置logo
  store.dispatch('setLogoFile');
};

start();
